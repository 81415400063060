code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
   font: 500 10px/1 'Noto Snas KR', 'Poppins', sans-serif;
   box-sizing: border-box; outline: none;
   padding: 0; margin: 0;
   line-height: 1;
   -ms-overflow-style: none; /** IE, Edge */
   scrollbar-width: none; /** firefox */
}
*::-webkit-scrollbar {
   display: none; /** chrome, safari, opera */
}
body {
  min-width: 375px;
}
a {
   text-decoration: none;
}
button {
   border: none; outline: none;
   background: none;
   cursor: pointer;
}
input, textarea {
   border: none; outline: none; resize: none;
   background: none;
   -webkit-user-select: auto;
   -moz-user-select: auto;
   user-select: auto;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   writing-mode: horizontal-tb;
}
input::-ms-clear {
   display: none;
}
select {
   border: none; outline: none;
   cursor: pointer;
}
ul {
   list-style: none;
}